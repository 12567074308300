import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
import { FrLogic } from '~/modules/FrLogic/v1/FrLogic';
import { expectType } from '~/utils/tsd';
export function createStrategyOverviewStore() {
    const methods = {
        loadData: async function loadData() {
            const overviews = await apirc.strategy.http('get', '/api/strategies/overviews').request({
                strategyName: store.filter.strategyName,
                account: store.filter.strategistAccount,
                symbolRoot: store.filter.instruments.join(','),
                tag: store.filter.tags.join(','),
                period: store.filter.period,
                ...store.sorter.httpParams,
                ...store.pagination.getHttpParams(),
            });
            store.pagination.setPagination(overviews);
            store.overviews = (overviews.data || []).map(resources => {
                return {
                    ...resources.strategy,
                    strategySignal: resources.strategySignal,
                    returnOnInvestment: resources.performanceOverview.returnOnInvestment,
                    recommended: resources.performanceOverview.recommended,
                    totalPnl: resources.performanceOverview.totalPnl,
                    winRate: resources.performanceOverview.winRate,
                    maxTotalLoss: resources.performanceOverview.maxTotalLoss,
                    maxTotalProfit: resources.performanceOverview.maxTotalProfit,
                    maxDrawdown: resources.performanceOverview.maxDrawdown,
                    positionSymbol: resources.positionChange?.symbol ?? null,
                    currentPosition: resources.positionChange?.currentPosition ?? null,
                    previousPosition: resources.positionChange?.previousPosition ?? null,
                    price: resources.positionChange?.price ?? null,
                    datetime: resources.positionChange?.datetime ?? null,
                };
            });
        },
    };
    const state = {
        overviews: [],
        pagination: FrLogic.pagination(),
        sorter: FrLogic.sortBy([
            'datetime',
            'account',
            'avgLoss',
            'avgPnl',
            'avgWin',
            'currentPosition',
            'drawdown',
            'maxConsecutiveLossTrades',
            'maxConsecutiveWinTrades',
            'maxDrawdown',
            'maxTotalLoss',
            'maxTotalLossDatetime',
            'maxTotalProfit',
            'maxTotalProfitDatetime',
            'numOfLossTrades',
            'numOfTrades',
            'numOfWinTrades',
            'previousPosition',
            'profitFactor',
            'returnOnInvestment',
            'strategyDisplayName',
            'strategyId',
            'strategyName',
            'symbol',
            'symbolRoot',
            'totalPnl',
            'winRate',
        ]),
        filter: {
            strategistAccount: '',
            strategyName: '',
            instruments: [],
            tags: [],
            period: expectType('ThreeMonths'),
        },
    };
    const store = proxy({
        ...state,
        ...methods,
    });
    return store;
}
