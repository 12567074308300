import { css } from '@emotion/react';
import { Button, PasswordInput, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useProxy } from 'valtio/utils';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
export const PageLoginForm = (props) => {
    const me = useProxy(pageStore.me);
    const form = useForm({
        initialValues: {
            account: props.initialValues?.account || '',
            password: props.initialValues?.password || '',
        },
    });
    return (<div css={css `
        width: 360px;
        padding: 36px;
      `}>
      <form onSubmit={form.onSubmit(values => {
            me.requestLogin(values).catch(err => {
                form.setFieldError('password', err?.message);
            });
        })}>
        <h2>{fr_agents.config.title}</h2>
        <TextInput label='帳號' {...form.getInputProps('account')}/>
        <PasswordInput label='密碼' {...form.getInputProps('password')}/>
        <Button type='submit'>登入</Button>
      </form>
    </div>);
};
