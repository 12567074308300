import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
import { FrLogic } from '~/modules/FrLogic/v1/FrLogic';
import { dayjs } from '@futures.tw/dayjs';
export function createStrategyStore() {
    const initialState = {
        filter: {
            dateRangeGte: dayjs().subtract(2, 'month').startOf('month'),
            dateRangeLte: dayjs(),
        },
    };
    const methods = {
        async loadStrategyDetails(strategyId) {
            try {
                store.loadStrategyDetailsLoading = true;
                const strategyDetails = await apirc.strategy
                    .http('get', '/api/strategies/{strategyId}')
                    .request({ strategyId });
                store.strategyProperties = strategyDetails;
                //
                //
                const statements = await apirc.strategy
                    .http('get', '/api/strategies/{strategyId}/statement')
                    .request({
                    strategyId,
                    'datetime[gte]': store.filter.dateRangeGte.startOf('day').format(),
                    'datetime[lte]': store.filter.dateRangeLte.endOf('day').format(),
                    ...store.statementsPagination.getHttpParams(),
                });
                store.statements = statements.trades?.data || [];
                store.statistics = statements.statistics;
                store.statementsPagination.setPagination({
                    ...statements.trades,
                });
                //
                //
                const portfolios = await apirc.strategy
                    .http('get', '/api/strategies/{strategyId}/dailyPnl')
                    .request({
                    strategyId,
                    'datetime[gte]': store.filter.dateRangeGte.startOf('day').format(),
                    'datetime[lte]': store.filter.dateRangeLte.endOf('day').format(),
                });
                store.portfolios = portfolios;
                //
                //
                const detailedPositions = await apirc.strategy
                    .http('get', '/api/strategies/{strategyId}/detailedPositions')
                    .request({ strategyId });
                store.detailsPositions = detailedPositions.data || [];
                store.loadStrategyDetailsLoading = false;
            }
            catch (error) {
                store.loadStrategyDetailsLoading = false;
            }
        },
        resetPagination() {
            store.statementsPagination.setPagination({ page: 1 });
        },
        resetFilter() {
            store.filter = {
                ...initialState.filter,
            };
        },
        /**
         * 傳入一個日期，將會以該日期的 0 時為起始日，同時自動以該日期的月份最後一天 23:59 為結束日
         *
         * @example
         *   // 若傳入 5/24
         *   // 則會設定為 5/24 00:00:00 ~ 5/31 23:59:59
         *
         *   // 若傳入 3/29
         *   // 則會設定為 3/29 00:00:00 ~ 3/31 23:59:59
         */
        setFilterDateRangeFrom(monthAsFrom) {
            store.filter.dateRangeLte = dayjs().endOf('month').setTime(24);
            store.filter.dateRangeGte = monthAsFrom.setTime(0);
        },
    };
    const store = proxy({
        loadStrategyDetailsLoading: false,
        detailsPositions: [],
        portfolios: [],
        statements: [],
        statementsPagination: FrLogic.pagination({ page: 1, pageSize: 25 }),
        statistics: undefined,
        strategyProperties: null,
        csvHistoryUploader: {
            loading: false,
            file: null,
            onlineDatetime: dayjs().startOf('day'),
            apiFormat: 'transactions',
        },
        csvHistoryExporter: {
            loading: false,
            error: null,
            apiFormat: 'transactions',
            includeSimulated: true,
            dateRangeGte: dayjs().startOf('year'),
            dateRangeLte: dayjs().endOf('day'),
        },
        filter: {
            ...initialState.filter,
        },
        showYAxisFromZero: false,
        ...methods,
    });
    return store;
}
