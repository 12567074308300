import decodeJwt from 'jwt-decode';
import { set } from 'lodash';
import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
import { FrLogic } from '~/modules/FrLogic/v1/FrLogic';
import dayAPI from '~/utils/dayAPI';
export function createMeStore() {
    //
    // private
    const _jwtStore = FrLogic.localStorage({ key: 'jwt', initialState: '' });
    const _decodeJwtStore = () => {
        if (_jwtStore.value) {
            return decodeJwt(_jwtStore.value);
        }
        return {};
    };
    //
    // methods
    const methods = {
        loadInfoMe: async function loadInfoMe() {
            const account = store.current?.account || _decodeJwtStore()?.sub || '__ACCOUNT_NOT_SET__';
            return await apirc.strategy
                .http('get', '/api/users/{account}')
                .request({ account })
                .then(remoteMeInfo => {
                store.current = remoteMeInfo;
            })
                .catch(error => {
                store.logout();
                return Promise.reject(error);
            });
        },
        requestLogin: async function requestLogin(params) {
            return await apirc.strategy
                .http('post', '/api/users/login')
                .request(params)
                .then(response => {
                if (!response.accessToken) {
                    throw new Error(`找不到 response.accessToken`);
                }
                _jwtStore.setValue(response.accessToken);
            })
                .then(store.loadInfoMe);
        },
        logout: function logout() {
            _jwtStore.removeValue();
            store.current = null;
            setTimeout(() => {
                location.reload();
            }, 500);
        },
        setRole(role) {
            set(store, 'current.role', role);
        },
        /** 根據 `jwt.exp` 來決定是否保留前端 me 登入狀態 */
        refreshMeLoginState: () => {
            const jwtExp = _decodeJwtStore()?.exp;
            if (!jwtExp) {
                return;
            }
            const exp = dayAPI(jwtExp * 1000);
            const now = dayAPI();
            const isExpired = exp.isBefore(now);
            console.info('refreshMeLoginState', {
                jwtExp,
                exp: exp.format(),
                now: now.format(),
                isExpired,
            });
            if (isExpired) {
                store.logout();
            }
        },
    };
    //
    // store
    const store = proxy({
        current: null,
        ...methods,
        get jwt() {
            return _jwtStore.value;
        },
        get hasLogin() {
            const hasJwt = !!_jwtStore.value;
            const hasMe = !!store.current;
            return hasJwt && hasMe;
        },
    });
    //
    // store initialization
    if (_jwtStore.value) {
        store.loadInfoMe();
    }
    return store;
}
